import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import Spacer from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Heading from '@interness/web-core/src/components/text/Heading/Heading';
import TeamMembers from '@interness/web-core/src/components/modules/TeamMembers';
import HeaderMedia from '@interness/theme-default/src/components/HeaderMedia';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderMedia id={'about'} mdxType="HeaderMedia" />
    <Spacer mdxType="Spacer" />
    <Wrapper mdxType="Wrapper">
  <Heading mdxType="Heading">Über uns</Heading>
      <p>{`  Die Hausverwaltung Meiners + Hinz OHG besteht mittlerweile seit über 10 Jahren und ist aus der Hausverwaltung Meiners
hervorgegangen.`}</p>
  <TeamMembers mdxType="TeamMembers" />
    </Wrapper>
    <Spacer mdxType="Spacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      